<template>
  <div class="section-container w-100 bg-white p-sm-3">
    <div class="container">
      <!-- whiteShadow -->
      <b-row class="verticalSpacing">
        <b-col cols="12" md="6" class="text-md-left text-center align-items-center">
          <!-- Ready to experience a new way of buying cars internationally? -->
          <span class="experience">{{ $t("Buy.Content.ReadyToExperience") }}</span>
        </b-col>
        <div class="col-md-6 col-12 container d-flex flex-column flex-sm-row mt-xl-0 gap-2 gap-md-4 mx-auto align-items-center ">
          <div class="w-100">
              <b-button variant="primary" pill class="radius-btn" :to="$t('Buy.Link.Search')">
                {{ $t("Buy.Content.StartNow") }}
              </b-button>
          </div>
          <div class="w-100">
            <b-button
              variant="outline-primary"
              pill
              class="radius-btn justify-content-center align-items-center d-flex w-100"
              @click="openModal"
            >
              <!-- Contact Sales -->
              {{ $t("Contact.CustomerServiceModal.Label") }}
            </b-button>
          </div>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    text: {
      type: Array,
    },
  },
  methods: {
    ...mapActions(['setCustomerServiceModal']),
    openModal() {
      this.setCustomerServiceModal(true)
    },
    showText(key) {
      let t;
      if (this.text) {
        this.text.forEach((element) => {
          if (element.Key === key) {
            t = element.Value;
          }
        });
      }
      return t;
    },
  },
};
</script>

<style lang="scss" scoped>
.experience {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  
  @media screen and (min-width: 992px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media screen and (min-width: 1200px) {
    font-weight: 600;
    font-size: 34px;
    line-height: 48px;
  }
}
.buttonText {
  padding: 16px 40px;
  width: 100%;
}

@media (max-width: 991px) {
  .experience {
    display: block;
  }
}
@media (min-width: 576px) {
  .buttonText {
    padding: 12px 24px;
  }
}
</style>
